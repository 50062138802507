import axios from 'axios'
import router from "router";
import { Message, Loading } from 'element-ui'
// const ConfigBaseURL = 'https://api-tw.esimart.com/api' //默認路徑，這裏也可以使用env來判斷環境
// const ConfigBaseURL = 'https://stage-api-tw.esimart.com/api' //默認路徑，這裏也可以使用env來判斷環境
const ConfigBaseURL = process.env.VUE_APP_BASE_API
let loadingInstance = null //这里是loading

//使用create方法创建axios实例
export const Service = axios.create({
  timeout: 30000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: 'post',
  headers: {
    // 'Content-Type': 'application/json;charset=UTF-8'
    'Content-Type': 'multipart/form-data'
  }
})

// 添加请求拦截器
Service.interceptors.request.use(config => {
  if (config.url != '/Exchange/queryResult') {
    loadingInstance = Loading.service({
      lock: true,
      text: 'loading...'
    })
  }
  config.headers.token = JSON.parse(window.localStorage.getItem('TOKEN_NAME'))
  return config
})

// 添加响应拦截器
Service.interceptors.response.use(response => {
  loadingInstance.close()
  if (response.data.code == 202) {
    window.localStorage.removeItem('TOKEN_NAME')
    window.localStorage.removeItem('userInfo')
    let status = this.$store.state.loginStatus;   // 重新赋值
    this.$store.commit("changeStatus", false);
    this.$nextTick(() => {
      this.$store.commit("changeuserInfo", JSON.stringify({}));
    })
  }
  return response.data
}, error => {
  console.log('TCL: error', error)
  const msg = error.Message !== undefined ? error.Message : ''
  Message({
    message: 'Network Error' + msg,
    type: 'error',
    duration: 3 * 1000
  })
  if (loadingInstance) {
    loadingInstance.close()
  }
  return Promise.reject(error)
})
